<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-button
        v-if="this.$route.name === 'apps-servicer' || this.$route.name === 'apps-servicers'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/servicer'"
        variant="primary"
        class="ml-1"
      >
        Dodaj serwisanta
      </b-button>
      <b-button
        v-if="this.$route.name === 'apps-representative' || this.$route.name === 'apps-representatives'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/representative'"
        variant="primary"
        class="ml-1"
      >
        Dodaj przedstawiciela
      </b-button>
      <b-button
        v-if="this.$route.name === 'apps-client' || this.$route.name === 'apps-clients'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/client'"
        variant="primary"
        class="ml-1"
      >
        Dodaj klienta
      </b-button>
      <b-button
        v-if="role_id == 1 && (this.$route.name === 'apps-service' || this.$route.name === 'apps-services')"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/service'"
        variant="primary"
        class="ml-1"
      >
        Dodaj Serwis
      </b-button>
      <b-button
        v-if="this.$route.name === 'apps-realizedservice' && this.$route.name === 'apps-realizedservices'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/realizedservice'"
        variant="primary"
        class="ml-1"
      >
        Realizuj serwis
      </b-button>
      <b-button
        v-if="this.$route.name === 'apps-reparatur' || this.$route.name === 'apps-reparatures'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/reparatur'"
        variant="primary"
        class="ml-1"
      >
        Dodaj Naprawę
      </b-button>
      <b-button
        v-if="this.$route.name === 'apps-user' || this.$route.name === 'apps-users'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :to="'/apps/user'"
        variant="primary"
        class="ml-1"
      >
        Dodaj Użytkownika
      </b-button>

      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>
{{this.$route.name}}
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BDropdown, BDropdownItem, BButton, VBToggle, BDropdownGroup,

} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'

export default {
  components: {
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    VBToggle,
    Ripple,
    BDropdownGroup,

    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    // SearchBar,
    DarkToggler,
    // CartDropdown,
  },
  data() {
    return {
      role_id: localStorage.getItem('id'),
    }
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
